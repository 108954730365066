import Button from '@mui/material/Button';
import { FaAngleDown } from "react-icons/fa";

const CountryDropDown = () => {
    return (
        <> <Button className='countryDrop '>
            <div className='dd d-flex flex-row'>
                <div className='info d-flex flex-column '>

                    <div className="label">Your Location</div>
                    <div className="Name">India</div>

                </div><span className="ms-1 w"><FaAngleDown /></span></div>



        </Button>
        </>
    )
}

export default CountryDropDown;