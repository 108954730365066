import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo1.png';
import CountryDropDown from '../CountryDropDown';
import SearchBox from '../SearchBox';
import CatTabb from '../CatTabb';
import Lgn from '../Lgn';
import Jam from '../Jam';

const Header = () => {
    return (
        <>

            {/* <div class="container">
                <div class="row row-cols-1 hr  d-flex justify-content-center bcol">
                    <div className="headerWrapper d-flex flex-row ">
                        <div className="part1"><Link to={'/'}><img src={logo} alt="go" /></Link></div>


                        <div className='part4'><SearchBox /></div>
                        <div className='nani'>
                            <div className='part5'><CountryDropDown /></div>
                            <div className='part6'><Lgn /></div>
                            <div className='part7'><Jam /></div>
                        </div>
                    </div >
                    <div className="headerWrapper2 d-flex flex-row ">
                        <div className='part3 '><CatTabb /></div>
                    </div>

                </div>
            </div> */}
            <div class="container overflow-hidden">
                <div class="row row-cols-1 hr  d-flex justify-content-center bcol">
                    <div class="col-4 col-md-6 col-xl-3 order-1 order-xl-1 d-flex justify-content-end part1 px-0 d-xl-flex justify-content-xl-end d-md-flex justify-content-md-center pe-lg-5"><Link to={'/'}><img src={logo} alt="go" /></Link></div>
                    <div class="col-md-4 col-lg-6 col-xl-4 order-3  col-6 order-xl-2 part4   "><SearchBox /></div>
                    <div class="col-8 col-md-6 order-2 col-xl-3 order-xl-3 d-flex justify-content-center  nani"><div className='nani'>
                        <div className='part5'><CountryDropDown /></div>
                        <div className='part6'><Lgn /></div>
                        <div className='part7'><Jam /></div>
                    </div>
                    </div>
                    <div className="headerWrapper2 order-4 order-xl-4 d-flex justify-content-start  mt-2 ">
                        <div className='part3  '><CatTabb /></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm e">col-sm</div>
                    <div class="col-sm e">col-sm</div>
                    <div class="col-sm e">col-sm</div>
                </div>
            </div>


        </>
    )
}

export default Header;