import Button from '@mui/material/Button';
import { FiUser } from "react-icons/fi";
const Lgn = () => {

    return (

        <div className=" d-flex flex-column align-items-center ">
            <div className="user"><Button className='d-flex flex-column align-items-center justify-content-center' ><FiUser /><p className='pop'>Login</p></Button></div>


        </div>

    )


}


export default Lgn;