import { IoSearch } from "react-icons/io5";
import Button from '@mui/material/Button';
const SearchBox = () => {
    return (
        <div className="headerSearch mb-2">
            <input type="text" placeholder='Happy Searching :-)' />
            <Button><IoSearch /></Button>
        </div>

    )
}

export default SearchBox;