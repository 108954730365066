import { BsCart4 } from "react-icons/bs";
import Button from '@mui/material/Button';
const Jam = () => {
    return (
        <div className='position-relative d-flex flex-column  align-items-center mx-2 bbol mt-1'>
            <div class=""><Button className="cartshop d-flex flex-column align-items-center justify-content-center"><BsCart4 />
            <p className="pop mt-1">Cart(1)</p></Button></div>

            <div className='position-absolute top-0 end-0  mt-0 d-flex count align-items-center justify-content-center r'>1</div>
            

        </div>


    )
}


export default Jam;