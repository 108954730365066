import { TiThMenu } from "react-icons/ti";
import { FaAngleDown } from "react-icons/fa";
import Button from '@mui/material/Button';

const CatTabb=()=>{
    return(



<Button className=" allCatTab align-items-center ">
    <span className="icon1 mb-1 me-2"><TiThMenu /></span>
    <span className="text">CATAGORIES</span>
    <span className="icon2 ms-2"><FaAngleDown /></span>
</Button>




    )
}

export default CatTabb;